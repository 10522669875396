import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import Info from '../components/Info'
import { FAQ } from '../data/Faq'
import { InfoPreview } from '../types'
import ApiService from '../services/ApiService'
import Loader from '../components/Loader'

export default function Account() {
    const { accessToken, logout } = useAuth()
    const navigate = useNavigate()

    const [data, setData] = useState<InfoPreview>()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await ApiService.get<InfoPreview>(
                    `/insurance/info`
                )
                setData(response)
                setIsLoading(false)
            } catch (error) {
                alert(
                    "We couldn't find your information. Please try again on the next screen."
                )

                navigate('/start')
            }
        }

        if (!accessToken) {
            navigate('/login')
        } else {
            fetchData()
        }
    }, [])

    if (isLoading) {
        return <Loader />
    }

    return (
        <article className="article">
            <h1>My Account</h1>
            {data !== undefined && (
                <>
                    <div className="account">
                        <div className="account__status">
                            Status: Covered{' '}
                            <img alt="Covered" src="/assets/check-icon.svg" />
                        </div>
                        <div className="account__document">
                            Document: <strong>{data.policyName}</strong>
                        </div>
                    </div>
                    <Info info={data} />
                    <div>
                        <button
                            className="button"
                            onClick={() => {
                                logout()
                                navigate('/')
                            }}
                        >
                            Logout
                        </button>
                    </div>
                </>
            )}
            {/*}
            <div className="account__payment">
                <h2>Payment Methods</h2>
                <p>You don't have a valid payment method.</p>
                <button className="button" type="button">
                    Add credit card
                </button>
            </div>
            {*/}
            <div className="faq">
                <h2>FAQ</h2>
                {FAQ.map((item, key) => (
                    <div key={key} className="faq__question">
                        <h3>{item.question}</h3>
                        <div
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                        />
                    </div>
                ))}
            </div>
        </article>
    )
}
