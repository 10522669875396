import { Link } from 'react-router-dom'

export default function Home() {
    return (
        <div className="home">
            <p>
                <img
                    alt="Lighthouse Security Deposit Alternative"
                    src="/assets/home.png"
                />
            </p>
            <h1>Lighthouse Security Deposit Alternative</h1>
            <p>
                Congratulations! You have been approved to use our Security
                Deposit Alternative as a more affordable coverage option at
                move-in.
            </p>
            <p>
                Answer a few short questions and save hundreds of dollars or
                more at move-in with Lighthouse!
            </p>
            <p style={{ marginTop: '60px' }}>
                <Link className="button" to="/start">
                    Let's get started!
                </Link>
            </p>
        </div>
    )
}
