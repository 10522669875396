import { InfoPreview, Quote } from '../types'
import { Link } from 'react-router-dom'
import { currencyFormatter, formatDate } from '../utils'

interface InfoProps {
    info: InfoPreview
}

export default function Info(props: InfoProps) {
    return (
        <div className="preview">
            <h2>Your Information</h2>
            <dl className="list">
                <dt>Name:</dt>
                <dd>
                    {props.info.firstName} {props.info.lastName}
                </dd>
                <dt>Property:</dt>
                <dd>{props.info.propertyName}</dd>
                <dt>Unit:</dt>
                <dd>{props.info.unitName}</dd>
                <dt>Property Manager:</dt>
                <dd>{props.info.propertyManager}</dd>
                <dt>Credit Amount:</dt>
                <dd>{currencyFormatter.format(props.info.coverageAmount)}</dd>
                <dt>Start Date:</dt>
                <dd>{formatDate(props.info.coverageStartDate)}</dd>
                <dt>Renewal Date:</dt>
                <dd>
                    {formatDate(
                        props.info.renewalDate !== undefined
                            ? props.info.renewalDate
                            : props.info.coverageEndDate
                    )}
                </dd>
            </dl>
        </div>
    )
}
