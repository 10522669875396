import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FieldValues, useForm } from 'react-hook-form'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import Loader from '../components/Loader'
import { useInfoPreviewContext } from '../context/InfoPreviewContext'
import Steps from '../components/Steps'
import { Config } from '../config'
import { AddressData, InfoPreview } from '../types'
import { useQuoteContext } from '../context/QuoteContext'
import ApiService from '../services/ApiService'

export default function Info() {
    const { register: registerInfo, handleSubmit: handleInfoSubmit } = useForm()
    const [loading, setLoading] = useState<boolean>(false)
    const [address, setAddress] = useState<AddressData | null>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const navigate = useNavigate()
    const infoPreviewContext = useInfoPreviewContext()
    const quoteContext = useQuoteContext()

    const onInfoSubmit = async (data: FieldValues) => {
        if (!address?.label) {
            alert('Please provide your current address')
            return
        }

        setLoading(true)

        try {
            const addressValue = address.value as any
            const location = await ApiService.get(
                `/properties/location/${addressValue.place_id}`
            )

            console.log('LOCATION', location)

            const response = await ApiService.post<InfoPreview>(
                '/insurance/initiate',
                {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    address: address?.label,
                    locationId: location.id,
                }
            )

            setLoading(false)
            setErrorMessage(null)
            infoPreviewContext!.update(response)
            if (response.quote) {
                quoteContext!.update(response.quote)
            }

            if (response.token) {
                localStorage.setItem('accessToken', response.token)
            }

            navigate('/preview')
        } catch (error) {
            setErrorMessage(
                'There was an error loading your information. Please make sure the email address you provided matches the email address from the invitation.'
            )
            setLoading(false)
        }
    }

    const onInfoError = () => {
        setErrorMessage(
            'Please make sure the information you provided is correct.'
        )
    }

    return (
        <>
            <Steps active={1} />
            {loading ? (
                <Loader />
            ) : (
                <form
                    className="form"
                    onSubmit={handleInfoSubmit(onInfoSubmit, onInfoError)}
                >
                    {errorMessage !== null && (
                        <div className="error">{errorMessage}</div>
                    )}
                    <h2>Personal Information</h2>
                    <fieldset className="fieldset">
                        <label className="label">First Name *</label>
                        <input
                            required={true}
                            className="input"
                            {...registerInfo('firstName')}
                        />
                    </fieldset>
                    <fieldset className="fieldset">
                        <label className="label">Last Name *</label>
                        <input
                            required={true}
                            className="input"
                            {...registerInfo('lastName')}
                        />
                    </fieldset>
                    <h2>Contact Information</h2>
                    <fieldset className="fieldset">
                        <label className="label">Email *</label>
                        <input
                            required={true}
                            type="email"
                            className="input"
                            {...registerInfo('email')}
                        />
                    </fieldset>
                    <fieldset className="fieldset">
                        <label className="label">
                            Your <strong>Current</strong> Address *
                        </label>
                        <GooglePlacesAutocomplete
                            apiKey={Config.gooplePlacesApiKey}
                            selectProps={{
                                address,
                                onChange: setAddress,
                                styles: {
                                    input: (provided: any) => ({
                                        ...provided,
                                        lineHeight: '50px',
                                        height: '50px',
                                    }),
                                },
                            }}
                        />
                    </fieldset>
                    <fieldset>
                        <fieldset className="fieldset">
                            <label className="label">Phone Number *</label>
                            <input
                                required={true}
                                className="input"
                                {...registerInfo('phoneNumber')}
                            />
                        </fieldset>
                    </fieldset>
                    <div className="notice">
                        By clicking continue you agree to the{' '}
                        <a
                            rel="noreferrer"
                            href="https://media.mylighthouse.co/docs/tenant-acknowledgment-agreement.pdf"
                            target="_blank"
                        >
                            tenant acknowledgment agreement
                        </a>{' '}
                        for the letter of credit you are applying. My Lighthouse{' '}
                        <a
                            rel="noreferrer"
                            href="https://media.mylighthouse.co/docs/consumer-credit-score-authorization.pdf"
                            target="_blank"
                        >
                            may use information from you to calculate your
                            Issuing Fee
                        </a>
                        . This information may be used by our partners and us
                        now and for future policy terms.
                    </div>
                    <fieldset className="align_right">
                        <button className="button" type="submit">
                            Continue
                        </button>
                    </fieldset>
                </form>
            )}
        </>
    )
}
