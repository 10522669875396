import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { FieldValues, useForm } from 'react-hook-form'
import { useInfoPreviewContext } from '../context/InfoPreviewContext'
import { useQuoteContext } from '../context/QuoteContext'
import { InfoPreview } from '../types'
import Loader from '../components/Loader'
import Steps from '../components/Steps'
import ApiService from '../services/ApiService'

export default function Verify() {
    const { register: registerInfo, handleSubmit: handleInfoSubmit } = useForm()
    const [loading, setLoading] = useState<boolean>(false)
    const [codeSent, setCodeSent] = useState<boolean>(false)
    const [addedPhoneNumber, setAddedPhoneNumber] = useState<string | null>(
        null
    )
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const navigate = useNavigate()
    const infoPreviewContext = useInfoPreviewContext()
    const infoPreview = useInfoPreviewContext()?.infoPreview
    const quoteContext = useQuoteContext()

    if (infoPreview === undefined) {
        return <Navigate to="/start" />
    }

    const onInfoSubmit = async (data: FieldValues) => {
        setLoading(true)

        if (data.phoneNumber !== undefined) {
            setAddedPhoneNumber(data.phoneNumber)
        }

        try {
            const response = await ApiService.post<InfoPreview>(
                '/insurance/initiate',
                {
                    firstName: infoPreview?.firstName,
                    lastName: infoPreview?.lastName,
                    email: infoPreview?.email,
                    phoneNumber: data.phoneNumber,
                    address: infoPreview?.address,
                }
            )

            setLoading(false)
            setErrorMessage(null)
            infoPreviewContext!.update(response)

            if (response.quote) {
                quoteContext!.update(response.quote)
            }
            navigate('/preview')
        } catch (error) {
            setErrorMessage(
                'There was an error loading your information. Please refresh this page and try again.'
            )
            setLoading(false)
        }

        /*
        if (data.verificationCode) {
            try {
                await ApiService.post<string>('/auth/check-verification-code', {
                    login: addedPhoneNumber,
                    code: parseInt(data.verificationCode, 10),
                })

                try {
                    const response = await ApiService.post<InfoPreview>(
                        '/insurance/initiate',
                        {
                            firstName: infoPreview?.firstName,
                            lastName: infoPreview?.lastName,
                            email: infoPreview?.email,
                            phoneNumber: data.phoneNumber,
                            address: infoPreview?.address,
                        }
                    )

                    setLoading(false)
                    setErrorMessage(null)
                    infoPreviewContext!.update(response)

                    if (response.quote) {
                        quoteContext!.update(response.quote)
                    }
                    navigate('/preview')
                } catch (error) {
                    setErrorMessage(
                        'There was an error loading your information. Please refresh this page and try again.'
                    )
                    setLoading(false)
                }
            } catch (error) {
                setErrorMessage('Invalid verification code. Please try again.')
                setLoading(false)
            }
        } else {
            try {
                await ApiService.post<string>('/auth/send-verification-code', {
                    login: data.phoneNumber,
                })

                setLoading(false)
                setErrorMessage(null)
                setCodeSent(true)
            } catch (error) {
                setErrorMessage('Please check your phone number and try again.')
                setLoading(false)
            }
        }
        */
    }

    const onTryAgain = (data: FieldValues) => {
        setCodeSent(false)
    }

    const onInfoError = () => {
        setErrorMessage(
            'Please make sure the information you provided is correct.'
        )
    }

    return (
        <>
            <Steps active={1} />
            {loading ? (
                <Loader />
            ) : (
                <form
                    className="form"
                    onSubmit={handleInfoSubmit(onInfoSubmit, onInfoError)}
                >
                    {errorMessage !== null && (
                        <div className="error">{errorMessage}</div>
                    )}
                    <h2>Your phone number</h2>
                    {codeSent ? (
                        <>
                            <div
                                className="notice"
                                style={{
                                    fontSize: '1rem',
                                    margin: '0 0 20px 0',
                                }}
                            >
                                Please enter the verification code we've texted
                                you
                            </div>
                            <fieldset className="fieldset">
                                <label className="label">
                                    Verification code *
                                </label>
                                <input
                                    required={true}
                                    type="number"
                                    className="input"
                                    {...registerInfo('verificationCode')}
                                />
                            </fieldset>
                            <div
                                className="notice"
                                style={{
                                    fontSize: '1rem',
                                    margin: '0 0 20px 0',
                                }}
                            >
                                Didn't receive the code?{' '}
                                <span className="link" onClick={onTryAgain}>
                                    Try again
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            <fieldset className="fieldset">
                                <label className="label">Phone Number *</label>
                                <input
                                    required={true}
                                    className="input"
                                    {...registerInfo('phoneNumber')}
                                />
                            </fieldset>
                            <div
                                className="notice"
                                style={{
                                    display: 'none',
                                    fontSize: '1rem',
                                    margin: '0 0 20px 0',
                                }}
                            >
                                We will text you your verfication code
                            </div>
                        </>
                    )}
                    <fieldset className="align_right">
                        <button className="button" type="submit">
                            Continue
                        </button>
                    </fieldset>
                </form>
            )}
        </>
    )
}
