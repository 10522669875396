import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { FieldValues, useForm } from 'react-hook-form'
import { useAuth } from '../context/AuthContext'
import Loader from '../components/Loader'
import ApiService from '../services/ApiService'

export default function Login() {
    const { register: registerInfo, handleSubmit: handleInfoSubmit } = useForm()
    const [loading, setLoading] = useState<boolean>(false)
    const [codeSent, setCodeSent] = useState<boolean>(false)
    const [addedPhoneNumber, setAddedPhoneNumber] = useState<string | null>(
        null
    )
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const navigate = useNavigate()

    const { accessToken, login } = useAuth()

    const onInfoSubmit = async (data: FieldValues) => {
        setLoading(true)

        if (data.phoneNumber !== undefined) {
            setAddedPhoneNumber(data.phoneNumber)
        }

        if (data.verificationCode) {
            try {
                const response = await ApiService.post<{ accessToken: string }>(
                    '/auth/check-verification-code',
                    {
                        login: addedPhoneNumber,
                        code: parseInt(data.verificationCode, 10),
                    }
                )

                login(response.accessToken)
                navigate('/account')
            } catch (error) {
                setErrorMessage('Invalid verification code. Please try again.')
                setLoading(false)
            }
        } else {
            try {
                await ApiService.post<string>('/auth/send-verification-code', {
                    login: data.phoneNumber,
                })

                setLoading(false)
                setErrorMessage(null)
                setCodeSent(true)
            } catch (error) {
                setErrorMessage('Please check your phone number and try again.')
                setLoading(false)
            }
        }
    }

    const onTryAgain = (data: FieldValues) => {
        setCodeSent(false)
    }

    const onInfoError = () => {
        setErrorMessage(
            'Please make sure the information you provided is correct.'
        )
    }
    return accessToken ? (
        <Navigate to="/account" />
    ) : (
        <>
            <h1>Login</h1>
            {loading ? (
                <Loader />
            ) : (
                <form
                    className="form form--login"
                    onSubmit={handleInfoSubmit(onInfoSubmit, onInfoError)}
                >
                    {errorMessage !== null && (
                        <div className="error">{errorMessage}</div>
                    )}
                    <h2>Your email address</h2>
                    {codeSent ? (
                        <>
                            <div
                                className="notice"
                                style={{
                                    fontSize: '1rem',
                                    margin: '0 0 20px 0',
                                }}
                            >
                                Please enter the verification code we've emailed
                                to you
                            </div>
                            <fieldset className="fieldset">
                                <label className="label">
                                    Verification code *
                                </label>
                                <input
                                    required={true}
                                    type="number"
                                    className="input"
                                    {...registerInfo('verificationCode')}
                                />
                            </fieldset>
                            <div
                                className="notice"
                                style={{
                                    fontSize: '1rem',
                                    margin: '0 0 20px 0',
                                }}
                            >
                                Didn't receive the code?{' '}
                                <span className="link" onClick={onTryAgain}>
                                    Try again
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            <fieldset className="fieldset">
                                <label className="label">Email Address *</label>
                                <input
                                    required={true}
                                    type="email"
                                    className="input"
                                    {...registerInfo('phoneNumber')}
                                />
                            </fieldset>
                            <div
                                className="notice"
                                style={{
                                    fontSize: '1rem',
                                    margin: '0 0 20px 0',
                                }}
                            >
                                We will text you your verfication code
                            </div>
                        </>
                    )}
                    <fieldset className="align_right">
                        <button className="button" type="submit">
                            Continue
                        </button>
                    </fieldset>
                </form>
            )}
        </>
    )
}
