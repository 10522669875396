import React, { createContext, useContext, useState, useEffect } from 'react'

interface AuthContextType {
    accessToken: string | null
    login: (token: string) => void
    logout: () => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuth = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}

interface AuthProviderProps {
    children: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [accessToken, setAccessToken] = useState<string | null>(
        localStorage.getItem('accessToken')
    )

    const login = (token: string) => {
        setAccessToken(token)
        localStorage.setItem('accessToken', token)
    }

    const logout = () => {
        setAccessToken(null)
        localStorage.removeItem('accessToken')
    }

    useEffect(() => {}, [accessToken])

    const authContextValue: AuthContextType = {
        accessToken,
        login,
        logout,
    }

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    )
}
