import { Link, NavLink, Outlet } from 'react-router-dom'
import HamburgerMenu from '../components/HamburgerMenu'

export default function Layout() {
    return (
        <div>
            <header>
                <div className="header__content">
                    <div className="header__logo">
                        <Link to="">
                            <img alt="Lighthouse" src="/lighthouse.svg" />
                        </Link>
                    </div>
                    <div className="header__links">
                        <NavLink
                            to="/faq"
                            rel="noreferrer"
                            target="_blank"
                            className="button"
                        >
                            Frequently Asked Questions
                        </NavLink>
                        <a
                            href="https://media.mylighthouse.co/docs/tenant-acknowledgment-agreement.pdf"
                            rel="noreferrer"
                            target="_blank"
                        >
                            Tenant Acknowledgement Agreement
                        </a>
                    </div>
                    <HamburgerMenu />
                </div>
            </header>
            <div className="page">
                <Outlet />
            </div>
        </div>
    )
}
