interface StepsProps {
    active: number
}

export default function Steps(props: StepsProps) {
    return (
        <ul className="steps">
            <li
                className={`steps__item${
                    props.active === 1 ? ' steps__item--active' : ''
                }`}
            >
                <span>
                    <strong>Step 1</strong>
                    Personal Information
                </span>
                <i></i>
            </li>
            <li
                className={`steps__item${
                    props.active === 2 ? ' steps__item--active' : ''
                }`}
            >
                <span>
                    <strong>Step 2</strong>
                    Background Questions
                </span>
                <i></i>
            </li>
            <li
                className={`steps__item${
                    props.active === 3 ? ' steps__item--active' : ''
                }`}
            >
                <span>
                    <strong>Step 3</strong>
                    Your Quote
                </span>
                <i></i>
            </li>
        </ul>
    )
}
