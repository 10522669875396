export const FAQ = [
    {
        question: 'What does Lighthouse do?',
        answer: '<p>Lighthouse makes move-in more convenient by partnering with landlords and property managers who want to offer their residents a modern, affordable alternative to larger cash security deposits.</p><p>Renters can purchase a much more affordable Letter of Credit (“LOC”) through Lighthouse which guarantees a property manager the protection they need from losses caused by damages beyond normal wear and tear, as well as unpaid rent. Renters benefit from Lighthouse by avoiding high move-in costs and making expenses like property damage much more manageable.</p>',
    },
    {
        question: 'What is a Letter of Credit?',
        answer: '<p>A Letter of Credit (“LOC”) is a financial instrument used as a form of security for the property manager or landlord. It’s a commitment, issued by Lighthouse on behalf of the renter to the property manager, to ensure the renter meets their standard financial obligations as outlined in their rental agreement, such as paying rent and/or not damaging the property (beyond normal wear & tear). If these conditions aren’t met, the LOC pays the landlord up to a predetermined amount for which the tenant has guaranteed. Lighthouse will then work with the tenant on collecting repayment; convenient repayment plans may be made available.</p>',
    },
    {
        question: 'How much does it cost?',
        answer: '<p>Rates vary based on factors such as a soft credit pull (<strong><em>which will not affect your credit</em></strong>) and the amount of credit requested by the landlord. In less than 2 minutes, you can get a quote and sign up - your property manager will immediately be notified that you’ve secured coverage so you can keep moving forward!</p>',
    },
    {
        question: 'Am I still responsible for damages?',
        answer: '<p>Yes. If your landlord or property manager files a valid request for payment from Lighthouse on loss of rent or property damage under applicable law, you are still financially responsible up to your credit limit for these expenses in addition to your annual fee. Convenient repayment plans may be made available.</p>',
    },
    {
        question: 'What if I change my mind after I sign up?',
        answer: "<p>It depends. If your coverage hasn't started yet, speak with your property manager. If they are willing to cancel your coverage before it begins, or you will no longer be moving in, have them reach out to us and we can initiate a full refund. Please note that processing fees will <strong>NOT</strong> be included in any refunds as each transaction costs us money. Once refunds are initiated it may take up to 5-10 business days for the funds to show up in your account. If your coverage started within the last 14 days, your property manager can still request a refund, however, it will be prorated for the remaining term. If your coverage began more than 14 days ago, we are unfortunately unable to issue any refunds.</p>",
    },
    {
        question: 'What happens if I renew my lease?',
        answer: '<p>Once purchased, Lighthouse renews automatically with each new lease term. Before your lease ends, you will receive a few emails from us reminding you of the upcoming renewal. If needed, you’ll be asked to review/update your payment information with Lighthouse. Your service fee will then be processed and your LOC will be renewed for the following lease term. Your property manager will immediately be notified so that your lease renewal is seamless!</p>',
    },
    {
        question: 'What should I do when I move out?',
        answer: '<p>First, it’s important you communicate with your property manager or landlord in advance about your plans to move out. Renewal payments are <strong><em>automated</em></strong> through Lighthouse so you’ll want to remind them to cancel your renewal with us as well in order to avoid being charged in error.</p><p>Next, you should document everything. Take photos, request & organize documents, save emails, etc… If your property manager requests funds from Lighthouse on your behalf and you believe there’s been a mistake, you’ll want to be able to prove you’ve met your obligations under the rental agreement in order to avoid owing anything additional after move-out.</p>',
    },
    {
        question: 'How is it different from a normal security deposit?',
        answer: '<p>Similar to a traditional deposit, it protects your property manager from financial losses related to loss of rent and property damage (beyond normal wear and tear), as defined by Applicable Law. However, instead of you paying the entire amount in cash at the start of a lease, with Lighthouse you can avoid high move-in costs by paying a much more affordable, but non-refundable annual fee. This fee secures a Letter of Credit, guaranteed by you, which assures the property manager a predetermined amount of funds, in addition to any other deposits they may request, should they experience a loss related to your tenancy. Cash deposits must be exhausted first before Lighthouse coverage can kick-in.</p>',
    },
    {
        question: 'Is this like Renters Insurance?',
        answer: '<p>No, Lighthouse is not an insurance product. Renters Insurance may cover loss of personal property, liability, and additional living expenses, whereas Lighthouse is for the protection of your landlord only. Tenants benefit from Lighthouse by avoiding high move-in costs and making expenses like property damage much more manageable.</p><p>Questions? Call us at (805) 272-0003</p>',
    },
]
