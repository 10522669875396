import { FieldValues, useForm } from 'react-hook-form'
import { TipOptionsProps } from '../../types'
import { useState } from 'react'

export default function TipOptions(props: TipOptionsProps) {
    const { register: registerQuestion, handleSubmit: handleFormSubmit } =
        useForm()
    const [tipAmount, setTipAmount] = useState<number | undefined>(undefined)

    const onFormSubmit = async (data: FieldValues) => {
        props.onSubmit(tipAmount)
    }

    return (
        <div>
            <div className="article" style={{ textAlign: 'center' }}>
                <h2>Thank You for Choosing Lighthouse!</h2>
                <p>
                    We're so glad you've entrusted us to help with your housing
                    needs, and our team works hard to ensure you have a smooth
                    and positive experience every step of the way.
                </p>
                <p>
                    If you've enjoyed our service, we kindly ask you to consider
                    leaving a tip. Tipping not only shows appreciation for the
                    extra care we put into helping you, but it also allows us to
                    continue offering exceptional support to others like you.
                </p>
                <p>
                    As a token of our gratitude and commitment to giving back,
                    we're proud to donate <strong>10% of all tips</strong> to,
                    helping to make a positive impact on our community. Your
                    generosity not only supports our team but also contributes
                    to a meaningful cause.
                </p>
            </div>

            <form className="form" onSubmit={handleFormSubmit(onFormSubmit)}>
                <fieldset className="fieldset">
                    <label className="label label--radio">
                        <input
                            type="radio"
                            required={true}
                            value="10"
                            {...registerQuestion('tipAmount')}
                            onClick={() => {
                                setTipAmount(10)
                            }}
                        />{' '}
                        $10
                    </label>
                    <label className="label label--radio">
                        <input
                            type="radio"
                            required={true}
                            value="5"
                            {...registerQuestion('tipAmount')}
                            onClick={() => {
                                setTipAmount(5)
                            }}
                        />{' '}
                        $5
                    </label>
                    <label className="label label--radio">
                        <input
                            type="radio"
                            required={true}
                            value="2"
                            {...registerQuestion('tipAmount')}
                            onClick={() => {
                                setTipAmount(2)
                            }}
                        />{' '}
                        $2
                    </label>
                    <label className="label label--radio">
                        <input
                            type="radio"
                            required={true}
                            value="0"
                            {...registerQuestion('tipAmount')}
                            onClick={() => {
                                setTipAmount(0)
                            }}
                        />{' '}
                        No financial donation at this time
                    </label>
                </fieldset>
                <p>
                    <button
                        type="submit"
                        disabled={tipAmount === undefined}
                        className="button"
                    >
                        Purchase
                    </button>
                </p>
            </form>
        </div>
    )
}
