import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Config } from '../config'

class ApiService {
    private api: AxiosInstance

    constructor() {
        this.api = axios.create({
            baseURL: Config.api,
        })

        this.api.interceptors.request.use(
            (config: AxiosRequestConfig) => {
                const token = localStorage.getItem('accessToken')
                if (token) {
                    config.headers = config.headers || {}
                    config.headers.Authorization = `Bearer ${token}`
                }
                return config
            },
            (error) => {
                return Promise.reject(error)
            }
        )
    }

    async get<T = any>(endpoint: string, params: object = {}): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.api.get<T>(endpoint, {
                params,
            })
            return response.data
        } catch (error) {
            console.error(`Failed to get data from ${endpoint}`, error)
            throw error
        }
    }

    async post<T = any>(endpoint: string, data: object = {}): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.api.post<T>(
                endpoint,
                data
            )
            return response.data
        } catch (error) {
            console.error(`Failed to post data to ${endpoint}`, error)
            throw error
        }
    }
}

export default new ApiService()
