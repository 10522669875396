import { createContext, useContext, useState } from 'react'
import { InfoPreview } from '../types'

interface InfoPreviewContextProviderProps {
    children: React.ReactNode
}

type InfoPreviewContextType = {
    infoPreview: InfoPreview | undefined
    update: (infoPreview: InfoPreview) => void
}

export const InfoPreviewContext = createContext<
    InfoPreviewContextType | undefined
>(undefined)
export const useInfoPreviewContext = () => useContext(InfoPreviewContext)

export const InfoPreviewContextProvider = ({
    children,
}: InfoPreviewContextProviderProps) => {
    const [infoPreview, setInfoPreview] = useState<InfoPreview | undefined>(
        undefined
    )

    const update = (value: InfoPreview | undefined) => {
        setInfoPreview(value)
    }

    const value = {
        infoPreview,
        update,
    }

    return (
        <InfoPreviewContext.Provider value={value}>
            {children}
        </InfoPreviewContext.Provider>
    )
}
