import { createContext, useContext, useState } from 'react'
import { Quote } from '../types'

interface QuoteContextProviderProps {
    children: React.ReactNode
}

type QuoteContextType = {
    quote: Quote | undefined
    update: (Quote: Quote) => void
}

export const QuoteContext = createContext<QuoteContextType | undefined>(
    undefined
)
export const useQuoteContext = () => useContext(QuoteContext)

export const QuoteContextProvider = ({
    children,
}: QuoteContextProviderProps) => {
    const [quote, setQuote] = useState<Quote | undefined>(undefined)

    const update = (value: Quote | undefined) => {
        setQuote(value)
    }

    const value = {
        quote,
        update,
    }

    return (
        <QuoteContext.Provider value={value}>{children}</QuoteContext.Provider>
    )
}
