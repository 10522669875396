import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }

    const closeMenu = () => {
        setIsOpen(false)
    }

    return (
        <>
            <nav>
                {isOpen && (
                    <ul className="menu">
                        <li>
                            <NavLink to="/start" onClick={closeMenu}>
                                Get Started
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/faq"
                                onClick={closeMenu}
                                rel="noreferrer"
                                target="_blank"
                            >
                                Frequently Asked Questions
                            </NavLink>
                        </li>
                        <li>
                            <a
                                href="https://media.mylighthouse.co/docs/tenant-acknowledgment-agreement.pdf"
                                rel="noreferrer"
                                target="_blank"
                                onClick={closeMenu}
                            >
                                Tenant Acknowledgement Agreement
                            </a>
                        </li>
                    </ul>
                )}
            </nav>
            <div className="hamburger" onClick={toggleMenu}>
                ☰
            </div>
        </>
    )
}

export default HamburgerMenu
